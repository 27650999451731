/* Remove padding and margin */
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&family=Lato:wght@400;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;700&family=Merriweather+Sans:wght@400;700&display=swap");
* {
  margin: 0;
  padding: 0;
  border: 0;
  /* font-family: "Faustina"; */
  font-family: 'Lato', sans-serif;
}

html, body {
  overflow-x: hidden;
}


.header {
  max-width: 100%;
  max-height: 132px;
  height: auto;
  margin-bottom: 2em;
  object-fit: contain;
  object-position: left;
}


.about-header {
  /* padding-left: 10%; */
  max-width: 100%;
  max-height: 132px;
  height: auto;
  text-align: center;
  object-position: center;
  margin: auto;
}

a {
  text-decoration: none;
  all: initial;
  all: unset;
}

#landing-section {
  margin: 0 3%;
}
#project-section {
  background-image: url("./images/project-background.png"),
    linear-gradient(rgb(12, 7, 7), white);
    box-shadow: 0 -100px 80px 8px white inset;
    background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  padding: 0 3%;
}
#art-section {
  /* background-image: url("./images/art-background.png"),
    linear-gradient(rgb(118, 103, 103), white); */
    background: radial-gradient(ellipse at top, #ffffff, transparent),
    radial-gradient(ellipse at bottom, #EAFBFC, transparent);
    box-shadow: 0 -20px 80px 60px white inset;
  background-image: url("./images/art-background.png");
  background-size:cover;
  background-position: top;
  background-repeat: no-repeat;
  padding: 0 3%;
}

#about-section {
    margin-top: 5%;
    padding: 0 3%;
}

.projects {
  padding: 8%;
  padding-top: 3%;
  /* background-image: url("./images/project-background.png"),
    linear-gradient(rgb(118, 103, 103), white); */
  /* background-size: cover;
  background-position: top;
  background-repeat: no-repeat; */
  /* box-shadow: 0 -100px 80px 8px white inset; */
}

.project-masonry {
  margin: 0 calc(100vh * 0.1352 - 68px);
}
.project-masonry-tweaked {
  /* padding: 3%; */
  margin: 0 calc(100vh * 0.1352 - 68px);
}

.art {
  padding: 8%;
  padding-top: 3%;

  /* padding-left: 10%;
    padding-right: 10%;
    padding-bottom:10%; */
  /* background: radial-gradient(ellipse at top, #ffffff, transparent),
    radial-gradient(ellipse at bottom, #EAFBFC, transparent); */
  /* background-image: url("./images/art-background.png"); */
  /* background-size: cover;
  background-position: top;
  background-repeat: no-repeat; */
  /* box-shadow: 0 -20px 80px 60px white inset; */
  text-align: right;
}
.profilePic {
  max-width: 100%;
  /* min-width: calc(vh /2); */
  padding: 1% 3%;
  border-radius: 10%;
}
.aboutMe {
  margin-bottom: 10%;
  align-items: center;
  text-align: center;
  overflow: visible;
}
.fun-aboutme {
  margin:2% 5%;
  font-size: 1em;
}

@media (max-width: 550px) {
  #landing-section {
    margin: 0 5%;
  }
  #project-section {
    padding: 0 5%;
  }
  #art-section {
    padding: 0 5%;
  }
  
  #about-section {
      padding: 0 5%;
  }

  .projects {
    padding: 3%;
  }
  .project-masonry {
    margin: 0 calc(100vh * 0.1352 - 80px);
  }
  .project-masonry-tweaked {
    padding: 7%;
    margin: 0 calc(100vh * 0.1352 - 80px);
  }
}

@media only screen and (min-width: 600px) and (max-width: 1020px) {
  .project-masonry {
    margin: 0 calc(100vh * 0.1352 - 100px);
  }
  .project-masonry-tweaked {
    /* padding: 3%; */
    margin: 0 calc(100vh * 0.1352 - 100px);
  }
  .profilePic {
    max-width: 70%;
    /* min-width: calc(vh /2); */
    padding: 5%;
    border-radius: 15%;
  }
}



.loader {
  background-color: white;
  overflow: hidden;
  height: 100vh;
  /* width:100%; */
  padding-top: 5vh;
  /* text-align: center; */
}

.loading-text {
  text-align: center;
  
}

.loader div {
  /* margin-top: 60%;
  margin-bottom: 20%;
  margin-left:auto;
  margin-right: auto; */
  margin: 5vh auto;
}

 .drop {
  position: relative;
  width: 20px;
  height: 20px;
  top: 8%;
  margin: 0 auto;
  background: #4c626a;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  -moz-animation-name: drip;
  -webkit-animation-name: drip;
  animation-name: drip;
  -moz-animation-timing-function: cubic-bezier(1, 0, 0.91, 0.19);
  -webkit-animation-timing-function: cubic-bezier(1, 0, 0.91, 0.19);
  animation-timing-function: cubic-bezier(1, 0, 0.91, 0.19);
  -moz-animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -moz-animation-iteration-count: 6;
  -webkit-animation-iteration-count: 6;
  animation-iteration-count: 6;
}

 .drop:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 30px solid #4c626a;
  top: -22px;
}

 .wave {
  position: relative;
  margin-top: 5vh;
  opacity: 0;
  top: 50vh;
  width: 5vh;
  height: 2.5vh;
  border: #4c626a 0.5vh solid;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -moz-animation-name: ripple;
  -webkit-animation-name: ripple;
  animation-name: ripple;
  -moz-animation-delay: 1.5s;
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
  -moz-animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -moz-animation-iteration-count: 6;
  -webkit-animation-iteration-count: 6;
  animation-iteration-count:6;
}

 .wave:after {
  content: "";
  position: absolute;
  opacity: 0;
  top: 50vh;
  left: 50vh;
  width: 3vh;
  height: 1.5vh;
  border: #4c626a .3vh solid;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -moz-animation-name: ripple-2;
  -webkit-animation-name: ripple-2;
  animation-name: ripple-2;
  -moz-animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -moz-animation-iteration-count: 6;
  -webkit-animation-iteration-count: 6;
  animation-iteration-count: 6;
}

/* @keyframes ripple {
  from {
    opacity: 1;
  }
  to {
    width: 60%;
    height: 30%;
    border-width: 1.5px;
    top: -100px;
    opacity: 0;
  }
}

@keyframes ripple-2 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    width: 20%;
    height: 10%;
    border-width: 1px;
    top: 100px;
    left: 200px;
  }
} */
@keyframes ripple {
  from {
    opacity: 1;
  }
  to {
    width: 60vh;
    height: 30vh;
    border-width: 0.25vh;
    top: 30vh;
    opacity: 0;
  }
}

@keyframes ripple-2 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    width: 20vh;
    height: 10vh;
    /* border-width: 0.15vh; */
    border-width: 0.75vh; 
    top: 30vh;
    left: 30vh;
  }
}

@keyframes drip {
  to {
    top: 50vh;
  }
}

/*---------------------------------------------------------------*/
@keyframes fadeInUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.fadeInUp-animation {
  animation: 1.25s fadeInUp;
  -moz-animation-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-animation-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}


@keyframes fadeInExpand {
  0% {
    transform: scale(200%);
    opacity: 0;
  }
  100% {
    transform: scale(100%);
    opacity: 1;
  }
}

.fadeInExpand-animation {
  animation: 1.5s fadeInExpand;
}

@keyframes fadeInExpand2 {
  0% {
    transform: scale(0%);
    opacity: 0;
  }
  100% {
    transform: scale(100%);
    opacity: 1;
  }
}

.fadeInExpand2-animation {
  animation: 1.5s fadeInExpand2;
}

/* .loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  z-index: 10;
}

.loader .ripple {
  position: relative;
  width: 100px;
  height: 100px;
  background-color: white;
  border-radius: 50%;
  animation: ripple-effect 2s ease-out forwards;
}

@keyframes ripple-effect {
  0% {
    width: 100px;
    height: 100px;
    opacity: 1;
  }
  100% {
    width: 2000px;
    height: 2000px;
    opacity: 0;
  }
} */

.signature_base {
  stroke-dasharray: 8000;
  animation: writeBase 4s infinite linear;
}

@keyframes writeBase {
  0% {
    stroke-dashoffset: 8000;
  }
  80% {
    stroke-dashoffset: 0;
  }
}

.signature_dash {
  stroke-dasharray: 1000;
  animation: writeDash 4s infinite linear;
}

@keyframes writeDash {
  0%,
  80% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}