.placeHolder {
  min-height: 600px;
}

.landing-container {
  position: relative;
  text-align: top;
  height: 80%;
  max-width: 100%;
  max-height: 500px;

  display: "flex";
  /* flex-direction: "column";
  justify-content: center; */
  color: #4c626a;
  display: block;
}

#background-img {
  position: absolute;
  top:3em;
  right: 0px;
  max-width: 100%;
  min-width: 10%;
  min-height: 300px;
  max-height: 550px;
  height: auto;
  object-fit: contain;
}

.landing-text {
  background: url("../images/text-fog.svg");
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  background-position: right;
  /* left: 10%; */
  margin-top: 8em;
  /* transform: translate(5%, 50%); */
  max-width: 100%;
  margin-left: 10%;
}

.landing-text img {
  max-width: 100%;
  height: auto;
  background-repeat: repeat-x;
}

.landing-text .my-desc {
  max-width: 390px;
  background-image: url("../images/fog.png");
  /* background-image: url("../images/text-fog.png"); */
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  /* font-weight:bolder; */
  margin-bottom: 1em;
  font-size: 1.15em;
}
.landing-text .top-text {
  max-width: 650px;
  background-image: url("../images/text-fog.png");
  background-size:cover;
  background-position: bottom;
  background-repeat:no-repeat;
  font-weight:bold;
  font-size: 1em;
}

/* For width 500px and smaller: */
@media only screen and (min-width: 1350px) {
  .placeHolder {
    min-height: 800px;
  }
  .landing-container {
    position: relative;
    text-align: left;
    margin-top: 0.5em;
    height: 80%;
    max-width: 100%;

    display: "flex";
  }

  #background-img {
    position: absolute;
    top:2em;
    right: 0px;
    max-width: 100%;
    min-width: 10%;
    min-height: 500px;
    max-height: 750px;
    height: auto;
    object-fit: contain;
  }

  .landing-text {
    background: url("../images/text-fog.svg");
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    background-position: right;
    margin-left: 10%;
    margin-top: 15%;
    /* transform: translate(5%, 50%); */
    max-width: 100%;
  }
}

@media (max-width: 750px) {
  .placeHolder {
    min-height: 500px;
  }
  .landing-container {
    position: relative;
    text-align: left;
    /* margin-left: 5%; */
    /* margin:5%; */
  }
  #background-img {
    position: absolute;
    top:0px;
    right: 0px;
    max-width: 100%;
    min-width: 50%;
    min-height: 500px;
    height: auto;
    object-fit:scale-down;
    /* margin: 5%; */
    /* margin-left: 5%; */
    /* object-fit: contain; */
  }

  .landing-text {
    position: absolute;
    margin-top: 20%;
    /* transform: translate(5%, 50%); */
    max-width: 100%;
    margin-left: 5%;
  }
  .landing-text h4 {
    max-width: 600px;
    background-image: url("../images/fog.png");
    background-size: cover;
    background-position: top;
    background-repeat: repeat;
    /* background-repeat: no-repeat; */
    font-size: 1.5em;
  }
  .landing-text img {
    background-image: url("../images/fog.png");
    background-position: left;
    background-size: cover;
    background-repeat: repeat-x;
  }
  .landing-text h5 {
    max-width: 390px;
    background-image: url("../images/text-fog.svg");
    background-size: contain;
    background-position: bottom;
    background-repeat: repeat-x;
    font-weight: lighter;
    margin-bottom: 0.5em;
  }
}
